import React, { Component } from 'react';
import styled from 'styled-components';
import Container from "./Core/Container";

const Styles = styled.div`

    .property-list {
        margin: 3.125rem 0;
        padding: 0 1.25rem;
    }

    .title, .subtitle {
        margin: 0 0 1.25rem;
    }

    .container {
        display: inline-block;
    }

    .grid-container {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.25rem;
        margin: 0 0 1.25rem;
        padding: 0;
    }
    
    .prop-top h3 {
        margin: 0 0 1rem 0;
        line-height: 1.1;
    }

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 28.125rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;
        transition: .2s;

      &:hover {
        box-shadow: 0 0.125rem 1.25rem 0 #a3e0d8;
        transition: .2s;
      }

        .prop {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            text-decoration: none;
            box-sizing: border-box;
            position: relative;
            color: #6E6E6E;
            cursor: pointer;

        }

        .prop-image {
            height: 19.375rem;
            width: 100%;
            background: #6E6E6E;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            .pets {

                height: 2.5rem;
                width: 2.5rem;
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                background: var(--primary);
                border-radius: 100%;
                
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    fill: var(--white);
                    margin: -.625rem 0 0 -.625rem;
                }
            }
        }

        .prop-info {
            padding: 1.25rem;
            /* height: 8.75rem; */
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: var(--black);

            .prop-town {
                font-size: .75rem;
            }

            .price {
                font-size: 1rem;
                font-weight: 700;
                margin: 1rem 0;
                float: right;
                color: var(--black);
                display: flex;
                flex-direction: column;
                align-items: end;

                span {
                    font-weight: 400;
                    font-size: .75rem;
                }
            }

            .prop-link {
                float: right;
                font-weight: 700;
                color: var(--primary);
            }

            .prop-foot {
                padding-top: .85rem;
                border-top: 1px solid #D8D8D8;
            }
        }
    }

    .search-button {
        border: none;
        background-color: var(--secondary);
        position: relative;
        height: 3.125rem;
        z-index: 1;
        border-radius: .313em;
        line-height: 3.125rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--white);
        text-align: center;
        outline: 0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
        display: inline;
        width: calc(50% - 0.3125rem);
        margin: 0;
        cursor: pointer;
        width: 100%;

        &:hover,
        &:focus {
            background: var(--lightenButton)
        }
    }


    @media only screen and (min-width: 1024px) {

        .property-list {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }

        .grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {
            height: 28.125rem;

        }

        .search-button {
            width: 30%;
            float: right;
        }
    }
`;

class PropertyListAPI extends Component {
    state = {};

    startSearch = () => {
        let app = "hottub-hideaways"

        var url = `https://www.snaptrip.com/searches/custom?search[region]=${this.props.region}&search[check_in_on]=${this.getNextDayOfWeek(new Date, 6)}&search[minimum_nights]=3${this.props.theme ? "&search[keyword]=" + this.props.theme : ""}`
        var utmParams = `&utm_source=${app}&utm_medium=affiliate-site`;

        //utm_campaign
        var campaign = location.href.includes("utm_campaign=") ? "utm_campaign=" + location.href.split("utm_campaign=")[1]: "";
        campaign = campaign.includes("&") ? campaign.split('&')[0] : campaign;
        campaign ?  utmParams = utmParams + '&' + campaign : "";

        //utm_term
        var term = location.href.includes("utm_term=") ? "utm_term=" + location.href.split("utm_term=")[1]: "";
        term = term.includes("&") ? term.split('&')[0] : term;
        term ?  utmParams = utmParams + '&' + term : "";

        //utm_content
        var content = location.href.includes("utm_content=") ? "utm_content=" + location.href.split("utm_content=")[1]: "";
        content = content.includes("&") ? content.split('&')[0] : content;
        content ?  utmParams = utmParams + '&' + content : "";

        this.openInNewTab(url + utmParams);
        this.dataLayerPush("outbound-click", "snaptrip search");
    }

    getNextDayOfWeek = (date, dayOfWeek) => {
        var resultDate = new Date(date.getTime());
    
        resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    
        return resultDate;
    }
 
    propRedirect = (e) => {
        var url = e.target.closest('.prop').dataset.url;
        let app = (process.env.GATSBY_APP_ENV || "affiliate-site") ;

        switch(true) {
            case app === "LWHT":
                app = "lodges-with-hot-tubs"
              break;
            case app === "CS":
            app = "cottage-holidays"
            break;
            case app === "CS":
                app = "coastal-stays"
              break;
            case app === "VH":
                app = "villa-holidays"
              break;
            case app === "CW":
                app = "cottage-weekend"
            break;
            case app === "DFH":
                app = "dog-friendly-hotels"
            break;
            case app === "FVR":
                app = "florida-vacation-rentals"
            break;
            case app === "LH":
                app = "lodge-holidays"
             break;
             case app === "LC":
                app = "luxury-cottages"
             break;
             case app === "RC":
                app = "remote-cottages"
             break;
             case app === "ROMC":
                app = "romantic-cottages"
             break;
             case app === "UH":
                app = "unique-holidays"
             break;
          }

        var utmParams = `utm_source=${app}&utm_medium=affiliate-site`;
        let connector = url.includes('?') ? "&" : "?";

        //utm_campaign
        var campaign = location.href.includes("utm_campaign=") ? "utm_campaign=" + location.href.split("utm_campaign=")[1]: "";
        campaign = campaign.includes("&") ? campaign.split('&')[0] : campaign;
        campaign ?  utmParams = utmParams + '&' + campaign : "";

        //utm_term
        var term = location.href.includes("utm_term=") ? "utm_term=" + location.href.split("utm_term=")[1]: "";
        term = term.includes("&") ? term.split('&')[0] : term;
        term ?  utmParams = utmParams + '&' + term : "";

        //utm_content
        var content = location.href.includes("utm_content=") ? "utm_content=" + location.href.split("utm_content=")[1]: "";
        content = content.includes("&") ? content.split('&')[0] : content;
        content ?  utmParams = utmParams + '&' + content : "";
        
        var aid = this.props.aid ? "aid=" + this.props.aid + "&" : "";

        this.openInNewTab(url + connector + aid+ utmParams);
        this.dataLayerPush("outbound-click", "snaptrip property");
    }

    dataLayerPush = (outboundEvent, outbountURL) => {
        if (typeof window !== 'undefined'){
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event': outboundEvent,  'outbound-destination': outbountURL});
        }
    }

    openInNewTab = (url) => {
        if (typeof window !== `undefined`) {
            var win = window.open('/redirect?url=' + url, '_blank');
            win.focus();
        }
    }

    render() {
        let app = this;
        let StProps = app.props.snaptripProperties || null;
        if (!StProps) return(
            <p></p>
        );

		StProps = StProps.filter((v,i,a)=>a.findIndex(v2=>(v2.name===v.name))===i)
        StProps = StProps.slice(0, 9);

        return (
            <Container id={ this.props.id ? this.props.id : null }>
                <Styles>
                    { StProps.length > 0 ? <section className="property-list">
                        <div className="container">
                            <div className="col-1 hidden-m"/>
                            <div className="col-10">
                                { this.props.title ? <h2 className="title">{this.props.title}</h2> : null }
                                { this.props.subtitle ? <p className="subtitle">{this.props.subtitle}</p> : null }
                                <ul className="grid-container">
                                    {StProps.map(function(property, index){
                                        return (
                                            <li key={ index } className="grid-item">
                                                <div className="prop" data-url={"https://www.snaptrip.com" + property.link} onClick={app.propRedirect}>
                                                    <div className="prop-image" style={{backgroundImage: "url(" + property.images[0].url + ')'}}>
                                                    { property.pets > 0 ?
                                                        <div className="pets">
                                                            <svg id="pet" viewBox="0 0 512 512">
                                                                <path d="m186 159c18 0 35-10 46-25 11-15 18-34 18-55 0-20-7-39-18-54-11-16-28-25-46-25-17 0-34 9-46 25-11 15-17 34-17 54 0 21 6 40 17 55 12 15 29 25 46 25z m0-129c18 0 34 23 34 49 0 27-16 50-34 50-18 0-33-23-33-50 0-26 15-49 33-49z m140 129c17 0 34-10 46-25 11-15 17-34 17-55 0-20-6-39-17-54-12-16-29-25-46-25-18 0-35 9-46 25-11 15-18 34-18 54 0 21 7 40 18 55 11 15 28 25 46 25z m0-129c18 0 33 23 33 49 0 27-15 50-33 50-18 0-34-23-34-50 0-26 16-49 34-49z m171 61c-10-13-25-21-40-21-16 0-30 8-41 21-9 13-14 30-14 47 0 18 5 34 14 47 11 14 25 22 41 22 15 0 30-8 40-22 10-13 15-29 15-47 0-17-5-34-15-47z m-40 86c-14 0-25-18-25-39 0-21 11-38 25-38 13 0 25 17 25 38 0 21-12 39-25 39z m-361 8c9-13 14-29 14-47 0-17-5-34-14-47-11-13-25-21-41-21-15 0-30 8-40 21-10 13-15 30-15 47 0 18 5 34 15 47 10 14 25 22 40 22 16 0 30-8 41-22z m-66-47c0-21 12-38 25-38 14 0 25 17 25 38 0 21-11 39-25 39-13 0-25-18-25-39z m226 63c-58 0-113 23-154 64-41 41-63 95-63 153 0 19 5 36 15 52 17 26 47 42 78 42 26 0 47-6 66-12 19-5 36-10 58-10 22 0 39 5 58 10 19 6 40 12 66 12 31 0 61-16 78-42 10-16 15-33 15-52 0-58-22-112-63-153-41-41-96-64-154-64z m177 252c-12 18-32 29-53 29-22 0-39-5-58-11-20-5-40-11-66-11-26 0-46 6-66 11-19 6-36 11-58 11-21 0-41-11-53-29-7-10-10-22-10-35 0-103 84-187 187-187 103 0 187 84 187 187 0 13-3 25-10 35z"></path>
                                                            </svg>
                                                        </div>
                                                    : null }
                                                    </div>
                                                    <div className="prop-info">
                                                        <div className="prop-top">
                                                            <h3>{property.name}</h3>
                                                            <p className="prop-town">{property.town.name}</p>
                                                            { property.price && <div className="price" >
                                                                <p><span>From: </span> £{property.price.originalPrice}</p>
                                                                <span>For a 3 night stay</span>
                                                            </div>}
                                                        </div>
                                                        <div className="prop-foot">
                                                            <span>Sleeps: {property.sleeps}</span>
                                                            <span className="prop-link">VIEW PROPERTY</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <button className="search-button" onClick={this.startSearch}>See all {this.props.region ? this.props.region : this.props.theme} properties</button>
                            </div>
                        </div>
                    </section> : null }

                </Styles>
            </Container>
        )
    }
}

export default PropertyListAPI;